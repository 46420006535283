import { slide as Menu } from 'react-burger-menu'
import { Link } from "react-router-dom";
import Routes from './Routes';

import './Menu.scss';

function NavMenu() {

    return (
        <Menu disableAutoFocus right>
            {Routes.map(({path, name, show}, key)=>{
                return(
                    show ? <Link to={path} className="bm-item" key={key} href={path}>{name}</Link> : null 
                );
            })}
      </Menu>
    );
}
export default NavMenu;