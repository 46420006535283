
const FormatPhoneNumber = phoneNumber => {
    const insertPos = [2, 4];
    return phoneNumber.toString().split('').map((char, index)=>{
        if (insertPos.indexOf(index) !== -1) {
            return `${char} `; 
        }
        return char;
    }).join('');
}

export default FormatPhoneNumber;