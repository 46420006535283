import React, { useContext } from 'react';
import Partner from './Partner';
import './Partners.scss';

// Context
import GlobalContext from '../../store/Context';

const Partners = () => {

    const { partners } = useContext(GlobalContext);

    return(
        <section className="partner-section">
            <h2>Sammarbeidspartnere</h2>
            <p>Selv om vi er et lite firma har vi et stort og bredt nettverk 
                som gjør oss i stand til å hente inn ressurser og kompetanse 
                til prosjektering og utførelse av både små og store prosjekter.</p>
            <div className="partner-board">
                {partners.map((partner, index)=>{
                    return(
                        <div key={index} className="partnerWrap"><Partner partner={partner}/></div>
                    )
                })}
            </div>
        </section>
    )
}

export default Partners