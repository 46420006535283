import { Link } from "react-router-dom";
import Routes from './Routes';

const HorizontalMenu = () => {
    return(
        <nav className="horizontal-menu">
            {Routes.map(({path, name, show}, key)=>{
                return(
                    show ? <Link to={path} className="hm-item" key={key} href={path}>{name}</Link> : null
                );
            })}
        </nav>
    );
}

export default HorizontalMenu;