import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from "react-router-dom";

const isLast = () => {};

const isFirst = () => {};

const ImageNav = (props) => {

    const history = useHistory();
    let match = useRouteMatch("/tjenester/:id");

    const previous = parseInt(match?.params?.id) === 1 ? '' : parseInt(match?.params?.id)-1;
    const next = parseInt(match?.params?.id) === props.imageCount-1 ? '' : parseInt(match?.params?.id)+1;

    useEffect(() => {
        const navigate = (e) => {

            // left : 37
            // right: 39

            if (e.keyCode === 37) {
                console.log('Which key', e.keyCode);
                history.push('/tjenester/' + previous);
            }

            if (e.keyCode === 39) {
                console.log('Which key', e.keyCode);
                history.push('/tjenester/' + next);
            }
        }
        window.addEventListener('keydown', navigate)
        return () => window.removeEventListener('keydown', navigate)
    }, [history, next, previous])

    return (
        <Fragment>
            <div className="left-nav">
               { /* <button>Previous</button> */ } 
            </div>
                {props.children}
            <div className="right-nav">
                { /* <button>Next</button> */}
            </div>
        </Fragment>
    );
} 

export default ImageNav;