import './FlexImage.scss'

const FlexImage = (props) => {

    const url = `/grafikk/${props.filename}`
    return(
        <div className="flex-image">
            <figure style={ {backgroundImage: `url(${url})`}}>

            </figure>
        </div>
    );
};

export default FlexImage;