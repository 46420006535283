import Gallery from '../components/Gallery/Gallery'; 
import {MailtoLink, PhonetoLink} from '../components/clickables/Clickables';

const Services = () => {

    return (
        <section className="page-content services">
            <h2>Vi tilbyr</h2>
            <p>
                Støping av gulv, betongvegger, betongtrapp, dekke, 
                systemforskaling og tradisjonell forskaling.
            </p>
            <p>
                Alt innen betong i Trondheim, Trøndelag og Nord-Norge utføres
                 av Nidaros betong og byggservice. Tlf: <PhonetoLink source={'47839242'}/> eller send oss mail på <MailtoLink source={'post@nidarosbetong.no'}/>, gratis befaring fra betongarbeider med 20 års erfaring.
            </p>
            <h2>Eksempler på leverte prosjekter</h2>
            <Gallery/>    
        </section>
    );
};

export default Services