import Partners from "./Partners";

const Partner = ({partner}) => {
    return(
        <figure>
            <a href={partner.url} target="_blank" rel="noreferrer">
                {
                    partner.logo.length === 0 ?
                    <span className="no-logo">{partner.companyName}</span>
                    : <img src={`/partners/${partner.logo}`} alt={partner.companyName} />
                }
            </a>
        </figure>
    );
};

export default Partner;