import React, { useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

const ImageClose = (props) => {

    const history = useHistory();
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                history.push('/tjenester');
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [history])

    return (
        <div className="closeImage">
            <Link to="/tjenester">Lukk</Link>
            {props.children}
        </div>
    );
}

export default ImageClose;