import React from 'react';
import { MailtoLink, PhonetoLink} from '../clickables/Clickables';

import styles from './Slogan.module.scss';


const Sloagen = (props) =>{
    return (
        <p className={styles.slogan}>
            <span className={styles.freeAsInfreeBeer}>Vi tilbyr gratis befaring og kostandsoverslag.</span>
            <span className={styles.sub}>Ring nå på <PhonetoLink source="47839242" /> eller send oss <MailtoLink  source="post@nidarosbetong.no" text="epost"/>.
            </span>
        </p>
    );
}

export default Sloagen;