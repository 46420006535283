import React, { createContext, useState } from 'react'
import {createStore} from 'redux';
import Logo from './components/logo/Logo';
import NavMenu from './components/navigation/Menu';
import HorizontalMenu from './components/navigation/HorizontalMenu';
import Routes from './components/navigation/Routes'
import Footer from './components/footer/Footer';
import NotFound from './pages/404';
import { Persons, Company, PartnerList, GalleryImages } from './store/GlobalData';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import Reducer from './store/Reducer'; 

import { GlobalContextProvider } from './store/Context'; 

import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";

import './styles/App.scss';

//const ReduxStore = createStore(Reducer)

function App() {

  const [persons] = useState(Persons)
  const [company] = useState(Company)
  const [partners] = useState(PartnerList)
  const [galleryImages] = useState(GalleryImages)

  const Context = { persons, company, partners, galleryImages };

  return (
      <GlobalContextProvider value={Context}>
        <div className="App">
          <HashRouter>
            <header className="App-header">
              <h1 className="hidden">{Context.company.name}</h1>
              <Logo />
              <NavMenu />
              <HorizontalMenu />
            </header>
            <main>
              <Switch>
                {Routes.map(({ path, Component }, key) => (
                  <Route
                    exact
                    path={path}
                    key={key}
                    render={(props) => (
                      <Component {...props}/>
                    )}
                  />
                ))}
                <Route component={NotFound} />
              </Switch>
            </main>
          </HashRouter>
            <Footer/>
        </div>
        </GlobalContextProvider>
  );
}

reportWebVitals(console.log);

export default App;
