import {Home, About, Contact, Services, Instagram} from '../../pages/index';

const paths = [
    { path: "/", name: "Hjem", Component: Home, show: true },
    { path: "/omoss", name: "Om oss", Component: About, show: true },
    { path: "/kontakt", name: "Kontakt oss", Component: Contact, show: true },
    { path: "/tjenester", name: "Tjenester", Component: Services, show: true },
    { path: "/tjenester/:id", name: "Tjenester - Utført jobb", Component: Services, show: false }
  //  { path: "/instagram", name: "Instagram", Component: Instagram, show: true }
];

export default paths;