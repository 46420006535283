
const NotFound = ()=>{
    return(
        <section className="notFoundPage">
            <h2>Oups!</h2>
            <p>Du har vist landet på en side som ikke eksisterer.</p>
        </section>
    );
}

export default NotFound;