import './image.scss';
import { Link } from 'react-router-dom';
import BaseImage from './BaseImage';

const Pictureframe = (props)=>{

    return(
        <div className="dynImage">
            <Link to={'/tjenester/'+props.id}>
                <BaseImage {...props} />
            </Link>
        </div>
    );
}

export default Pictureframe;