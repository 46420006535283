import { useContext } from 'react';

// Icons
import PhoneIcon from '../icons/PhoneIcon';
import MailIcon from '../icons/MailIcon';
import ClockIcon from '../icons/ClockIcon';
import FacebookIcon from '../icons/FacebookIcon';

// Components
import PhoneNumbers from '../Phonenumbers/PhoneNumbers';
import Copyright from '../Copyright/Copyright';

// Context
import GlobalContext from '../../store/Context';

import './Footer.scss';

const Footer = (props) => {

  const Context = useContext(GlobalContext)

  /*
  Context.persons.set([...Context.Persons.get, {
    epost: "webadmin@nidarosbetong.no",
    navn: "Christian Skaiaa",
    rolle: "Datadude",
    telefon: "91712234"
  }]);
  */

  console.log('[footer.js - context]', Context);

  const phoneNumbers = Context.persons.map(person => person.telefon);  
  const company = Context.company;

  return (
    <footer className="footer">
      <div className="contact-info">
        <ul className="contact-list">
          <li>
            <PhoneIcon />
            <PhoneNumbers numbers={phoneNumbers} />
          </li>
          <li>
            <MailIcon />
            <a href={`mailto:${company.mail}`}>{company.mail}</a>
          </li>
          <li>
            <ClockIcon />
            <span>{company.hours}</span>
          </li>
          <li>
            <FacebookIcon />
            <a href={company.facebook}>Facebook</a>
          </li>
        </ul>
        <Copyright company={company} />
      </div>
    </footer>
  );
}

export default Footer;