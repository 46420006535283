import SimpleMap from '../components/Map/Map';
import {ExternalLink, MailtoLink, PhonetoLink} from '../components/clickables/Clickables';
import { useContext } from 'react';

// Context
import GlobalContext from '../store/Context';

const Contact = (props) => {

    const { company } = useContext(GlobalContext)
    
    return (
        <section className="page-content home">
            <h2>Kontakt oss</h2>

            <ul className="contactlist"> 
                <li><ExternalLink source="https://www.google.com/maps/@63.3857927,10.4068042,18z" text={company.adresse}/></li>
                <li><PhonetoLink source={company.phone} text={company.phone}/></li>
                <li><MailtoLink source={company.mail} text={company.mail}/></li>
                <li><ExternalLink source={company.facebook} text="Facebook"/></li>
                <li><ExternalLink source={company.instagram} text="Instagram"/></li>
            </ul>
            <h2>Du finner oss her:</h2>
            <SimpleMap/>
        </section>
    );
};

export default Contact;