import Partners from '../components/Partners/Partners';
import { Link } from "react-router-dom";
import { useContext } from 'react';

// Context
import GlobalContext from '../store/Context';

const About = function(props) {

    const { persons } = useContext(GlobalContext)

    function getPersonByFirstName(persons, navn){
        return persons.find((person)=>{
            return person.navn.startsWith(navn);
        });
    }

    const Lars = getPersonByFirstName(persons, 'Lars');
    const Erlend = getPersonByFirstName(persons, 'Erlend');

    return (
        <section className="page-content about">
            <h2>Om oss</h2>
            <p>Nidaros Betong og Byggservice ble startet i 2019 av <a href={`mailto:${Erlend.epost}`}>{Erlend.navn}</a> og <a href={`mailto:${Lars.epost}`}>{Lars.navn}</a>.
                De har begge lang fartstid i bransjen med store og små prosjekter
                 innen forskarling, armering og støping. Nidaros Betong <Link to="/kontakt" href="/kontakt">holder 
                 til sentralt i Trondheim</Link> , men har <Link to="/tjenester" href="/tjenester">prosjekter</Link> over hele Trønderlag.</p>

            <p>
            Vi fokuserer på å finne gode løsninger sammen med kunde slik at vi kan levere 
            høy kvalitet til rett tid og pris. Vi er fleksible og løsningsorienterte og 
            finner de rette teknikker for å løse jobben tilfredstillende for kunden.
            </p>
            <p>
            Nidaros Betong har høy fokus på HMS. Det er kontinuerlig fokus på
             sikkerhet og korrekt gjennomføring av arbeidet og prosjekt både hos oss selv og våre underleverandører.
            </p>
            <Partners/>
        </section>
        
    );
}

export default About;

