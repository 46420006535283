import './Checklist.scss';
import {Fragment} from 'react';

const ChecklistIcon = (props) => {
    return (
        <Fragment>
            { props.children.length > 0 ?
                <span className="checklistText">{props.children}</span>
                : null
            }
        </Fragment>
    )
};

const ChecklistItem = (props) => {
    return (
        <li>
            <ChecklistIcon>
                {props.item}
            </ChecklistIcon>
        </li>
    )
};

const Checklist = (props) => {
    return (
        <ul className="checklist">
            { props.items.map((item, index)=>{
                return(<ChecklistItem key={index} item={item}/>)
            })}
        </ul>
    );
};

export {Checklist, ChecklistItem, ChecklistIcon}
export default Checklist;

