import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div style={{border: '1px solid #000', width:'60px'}}>{text}</div>;

// 63.38576415109756, 10.407053935526644

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 63.38576415109756,
      lng: 10.407053935526644
    },
    zoom: 15
  };

  render() {
    return (
      // Important! Always set the container height explicitly , 
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyA80wBRzsb_pOCcs9IqWk0UhN5hgEqwsok' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={63.38576415109756}
            lng={10.407053935526644}
            text="Nidaros Betong og Byggservice"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;