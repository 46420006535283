import Pictureframe from './Image';
import BaseImage from './BaseImage';
import ImageClose from './navigation/ImageClose';
import ImageNav from './navigation/ImageNav';

import './Gallery.scss';
import { Fragment, useContext } from 'react';
import { useRouteMatch } from "react-router-dom";

// Context
import GlobalContext from '../../store/Context';

const Gallery = (props)=>{

    const { galleryImages } = useContext(GlobalContext);

    // console.log('[gallery.js]', galleryImages);

    let match = useRouteMatch("/tjenester/:id");

    const findImageByID = (id) =>{
        let searchID = parseInt(id);
        return galleryImages.find((image)=>{
            return image.id === searchID;
        });
    }


    console.log('Whooop', match?.isExact, match?.params?.id);

    return(
        <Fragment>
            { match?.isExact
                ? <ImageNav imageCount={galleryImages.length}>
                        <ImageClose>
                            <div className="singleImage">
                                <BaseImage {...findImageByID(match?.params?.id)}/>
                            </div>
                        </ImageClose>
                    </ImageNav>
                : <div className="gallery">
                    {galleryImages.map((item, index) => <Pictureframe src={item.src} alt={item.alt} caption={item.caption} key={index} id={item.id}/>)}
                </div> 
            }
        </Fragment>
    );
}
export default Gallery;