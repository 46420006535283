
const Persons = [{
    navn: 'Erlend Hårstad',
    epost: 'post@nidarosbetong.no',
    telefon: '47839242',
    rolle: 'Daglig Leder / Eier'
},{
    navn: 'Lars Haugrønning',
    epost: 'lars@nidarosbetong.no',
    telefon: '45210017',
    rolle: 'Faglig leder / Eier'
}];


const Company = {
    orgnr: '923592679', // organisasjonsnummer
    name: 'Nidaros Betong og Byggservice',
    adresse: 'Nordslettvegen 3, 7038 Trondheim',
    phone: '47839242',
    mail: 'post@nidarosbetong.no',
    hours: '07:00-15:00',
    facebook: 'https://www.facebook.com/nidarosbetong',
    instagram: 'https://www.instagram.com/nidaros_betong_og_byggservice/'
};

const PartnerList = [
    {companyName: 'Haucon Nord', url: 'https://www.haucon.no/article/kontaktinfo/trondheim', logo: 'haucon.png'},
    {companyName: 'Luna bygg', url: 'https://lunabygg.no/', logo: 'lunabygg.png'},
    {companyName: 'BM UTEMILJØ', url: 'https://bmutemiljo.no', logo: 'bmutemiljo.png'},
    {companyName: 'Betong Øst', url: 'https://betongost.no/betong-ost-trondelag/', logo: 'betongost.png'},
    {companyName: "Bygger´n Heimdal", url: 'https://www.byggern.no/butikker/bygger-n-heimdal', logo: 'byggern.png'},
    {companyName: 'Anleggsgartner 1', url: 'https://anleggsgartner1.com', logo: 'anleggsgartner1.png'},
    {companyName: 'RS-Bygg og Eiendom as', url: 'https://www.facebook.com/rsbyggogeiendomas/', logo: 'rsbygg.png'},
];

const GalleryImages = [
    {
        id: 1,
        src: '/jobs/scaled/bygaard.jpg',
        alt: 'Bygård Nonnegata 16',
        caption: 'Bygård nonnegata 16'
    },
    {
        id: 2,
        src: '/jobs/scaled/enebolig.jpg',
        alt: 'Enebolig Malvik for Norgeshus',
        caption: 'Enebolig Malvik for Norgeshus'
    },
    {
        id: 3,
        src: '/jobs/scaled/firemannsbolig.jpg',
        alt: 'Fire-mannsbolig i Haugnessvingen 11',
        caption: 'Fire-mannsbolig i Haugnessvingen 11'
    },
    {
        id: 4,
        src: '/jobs/scaled/stottemur.jpg',
        alt: 'Støttemur Even Lorengs veg',
        caption: 'Støttemur Even Lorengs veg'
    },
    {
        id: 5,
        src: '/jobs/scaled/trapp.jpg',
        alt: 'Støping og flislegging av betongtrapp i Martin Sæterhaugs veg',
        caption: 'Støping og flislegging av betongtrapp i Martin Sæterhaugs veg'
    }
];

export { Company, Persons, PartnerList, GalleryImages }
export default Persons;