import './Logo.scss';
import firmlogo from './logo-cropped.png';
import { Link } from "react-router-dom";

function Logo() {

    return (
        <div className="logo">
            <figure>
            <Link to="/" href="/">
                <img src={firmlogo} alt="Nidaros Bygg og Betongservice logo"/>
            </Link>
            </figure>
        </div>
    );
}

export default Logo; 