import { Fragment } from 'react';
import FormatPhoneNumber from '../../utils/FormatPhoneNumber';

const PhoneNumber = (props) => {

    function isLastElement(index){
        return (props.numbers.length === index+1);
    }

    return(
        <Fragment>
            {props.numbers.map((phoneNumber, index)=>{
                return (
                    <Fragment key={index}>
                        <a key={index} href={`tel:${phoneNumber}`}>{FormatPhoneNumber(phoneNumber)}</a>
                        { isLastElement(index) ? null : <span> / </span>}
                    </Fragment>
                )
            })}
        </Fragment>
    );
}

export default PhoneNumber;