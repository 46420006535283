import Checklist from '../components/checklist/Checklist';
import {Fragment} from 'react';
import FlexImage from '../components/FlexImage/FlexImage';
import Sloagen from '../components/Slogan/Slogan';

const ChecklistText = [
    'Godt og dønn solid fagarbeid',
    'Rett pris til rett tid',
    'Gratis befaring og kostandsoverslag',
    'Store og små prosjekter'
];

const Home = ()=> {

    return (
        <Fragment>
            <section className="page-content home">
                <h2>
                    <span className="headingSub">Velkommen til</span>
                    Nidaros Betong og Byggservice
                </h2>  
                <Checklist items={ChecklistText}/>
                <Sloagen/>
            </section>
            <FlexImage filename="forskaling-head-cropped.jpg"/>
        </Fragment>
    );
}

export default Home;