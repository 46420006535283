import {Fragment} from 'react';
import FormatPhoneNumber from '../../utils/FormatPhoneNumber';

/**
 * 
 * @param {{text: string, source: string}} props 
 */
const ExternalLink = (props)=>{
    return (
        <Fragment>
            <a href={props.source} target="_blank" rel="noreferrer">{props.text}</a>
        </Fragment>
        
    );
}

/**
 * 
 * @param {{text: string, source: string}} props 
 */
const MailtoLink = (props)=>{
    const text = props?.text ? props?.text : props.source;
    return (
        <Fragment>
            <a href={`mailto:${props.source}`}>{text}</a>
        </Fragment>
        
    );
}

/**
 * 
 * @param {{text: string, source: string}} props 
 */
const PhonetoLink = (props)=>{
    let text = props.text ? props.text : props.source;
    text = FormatPhoneNumber(text);
    return (
        <Fragment>
            <a href={`tel:${props.source}`}>{text}</a>
        </Fragment>
        
    );
}

export {ExternalLink, MailtoLink, PhonetoLink};
export default ExternalLink;